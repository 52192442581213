
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import LoginLayout from './Layouts/LoginLayout'

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />}/>
        
          <Route path="/" index element={<LoginLayout/>} />
          
        
      </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App
