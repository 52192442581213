import { Password } from '@mui/icons-material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import gj_side_logo from "../assets/gj_logo.png"
const LoginLayout = () => {

const[email,setEmail]=useState();
const[password,setPassword]=useState();
const navigate = useNavigate();

const handleSubmit=(e)=>{
    // e.preventDefault();
    console.log(email);
    if(email==="admin@guidejuniors.com"&&password==="Admin@123"){
        navigate('/dashboard')
    }
}

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        background: 'linear-gradient(to right,#001510, #078061 )'
      }}
    >
      <div className='' style={{ margin: 'auto' }}>
        <div
          style={{
            width: '40rem',
            background: 'white',
            borderRadius: '5px',
            padding: '20px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex',width:"20rem" }}>
              <div style={{margin:"auto"}}>
                <div style={{background:"#245D51",padding:"10px",borderRadius:"5px 20px 5px 20px"}}>
                    <img src={gj_side_logo} alt='Side Logo' width={100} style={{backgroundSize:"cover",objectFit:"cover",boxSizing:"border-box"}} />
                </div>
                
              </div>
            </div>
            <div style={{width:"20rem"}}>
              <div>
                
                <h1 style={{fontWeight:"700"}}>Sign In</h1>
              </div>
<div style={{fontWeight:"700",fontSize:"1.2rem"}}>Welcome GJ Admin</div>
              <form onSubmit={handleSubmit}>
                <div style={{ padding: '10px 0px 10px 0px' }}>
                  <input
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    style={{
                      padding: '10px',
                      width: '100%',
                      boxSizing: 'border-box',
                      fontSize:"1rem"
                    }}
                  />
                </div>
                <div style={{ padding: '10px 0px' }}>
                  <input
                    type='password'
                    placeholder='password'
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    style={{
                      padding: '10px',
                      width: '100%',
                      boxSizing: 'border-box',
                      fontSize:"1rem"
                    }}
                  />
                </div>

                <div style={{ padding: '10px 0px' }}>
                  <button
                  type="submit"
                    style={{
                      color: 'white',
                      padding: '10px',
                      width: '100%',
                      border: 'none',
                      background: '#FBA93E',
                      borderRadius: '5px',
                      fontSize:"1rem"
                    }}
                  >
                    Sign In
                  </button>
                </div>
              </form>
              {/* <div>For Register?<span style={{color:"#FBA93E"}}>Sign Up</span></div> */}
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  )
}

export default LoginLayout